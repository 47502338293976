<template>
    <v-container fluid>
    </v-container>
</template>

<script>
export default {
    components: { },
    name: 'test-page',
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    created() {
    }
}
</script>

<style>

</style>